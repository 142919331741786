var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-header", { staticClass: "tituloPagina" }, [
        _c(
          "h1",
          {
            staticClass: "tituloPaginaTexto",
            staticStyle: { color: "#ffffff", "padding-top": "2px" }
          },
          [
            _c("i", {
              class: _vm.icono,
              staticStyle: {
                color: "#ffffff",
                "margin-right": "6px",
                "padding-left": "4px"
              }
            }),
            _vm._v(" " + _vm._s(_vm.nombrePagina) + " ")
          ]
        )
      ]),
      _c("div", { staticStyle: { height: "50px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }