var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "sidebar" } },
        [
          _vm.$store.state.token != null
            ? _c(
                "el-aside",
                {
                  attrs: {
                    id: "mainSidebar",
                    width: _vm.$store.state.sidebar ? "200px" : "65px"
                  }
                },
                [
                  _c("div", { attrs: { id: "sidebarCabezera" } }, [
                    _c("div", { attrs: { id: "contenedorImagenCabezera" } }, [
                      _vm.$store.state.sidebar == true
                        ? _c("img", {
                            attrs: {
                              src: require("../../../assets/logoestrella.png"),
                              alt: "Logo",
                              width: "120px"
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "el-scrollbar",
                    { attrs: { "wrap-class": "scrollbar-wrapper" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { height: "auto" } },
                        [
                          _c(
                            "el-menu",
                            {
                              attrs: {
                                id: "sidemenu",
                                "background-color": "#1D282D",
                                "text-color": "#ffffff",
                                "collapse-transition": false,
                                collapse: !_vm.$store.state.sidebar,
                                "default-active": _vm.itemMenuActivo,
                                "default-openeds": _vm.menuItemsAbiertos
                              }
                            },
                            [
                              _vm._l(_vm.menuItems, function(item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    item.children == null &&
                                    _vm.esItemVisible(item)
                                      ? _c(
                                          "el-menu-item",
                                          {
                                            attrs: { index: item.path },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push(
                                                  item.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "estilobotones",
                                              class: item.icon
                                            }),
                                            _c("span", {
                                              attrs: { slot: "title" },
                                              domProps: {
                                                textContent: _vm._s(item.name)
                                              },
                                              slot: "title"
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    item.children != null &&
                                    _vm.esMenuVisible(item)
                                      ? _c(
                                          "el-submenu",
                                          { attrs: { index: item.path } },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "estilobotones",
                                                    class: item.icon
                                                  }),
                                                  _vm.$store.state.sidebar ==
                                                  true
                                                    ? _c("span", {
                                                        attrs: {
                                                          slot: "title"
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.name
                                                          )
                                                        },
                                                        slot: "title"
                                                      })
                                                    : _vm._e(),
                                                  _vm.$store.state.sidebar ==
                                                  true
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-down animacionIcono",
                                                        staticStyle: {
                                                          margin: "auto",
                                                          "margin-right": "0px"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            ]),
                                            _vm._l(item.children, function(
                                              child,
                                              j
                                            ) {
                                              return _c(
                                                "div",
                                                { key: j },
                                                [
                                                  _vm.esItemVisible(child)
                                                    ? _c(
                                                        "el-menu-item",
                                                        {
                                                          attrs: {
                                                            index: child.path
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$router.push(
                                                                child.path
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "estilobotones",
                                                            class: child.icon
                                                          }),
                                                          _c("span", {
                                                            attrs: {
                                                              slot: "title"
                                                            },
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                child.name
                                                              )
                                                            },
                                                            slot: "title"
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "logout" },
                                  on: { click: _vm.logout }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-switch-button estilobotones"
                                  }),
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v("Cerrar sesión")]
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }