<template>
  <div>
    <el-input
      v-model="valor"
      :prefix-icon="icon"
      :placeholder="placeholder"
      :clearable="clearable"
      :disabled="disabled"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    delay: {
      type: String,
      default: "650",
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  name: "nuevo-rol",
  data() {
    return {
      valor: null,
    };
  },
  methods: {
    refrescarValor() {
      let finished = false;
      let cancel = () => (finished = true);

      this.promise = new Promise((resolve, reject) => {
        let counts = 0;
        if (this.id2 == null) {
          this.id2 = setInterval(() => {
            counts += 1;
            //console.log(counts);
            //console.log(this.id2);
            if (counts == 1) {
              clearInterval(this.id2);
              this.id2 = null;
              //console.log("Ready or not, here I come!");
              resolve();
            }
          }, this.delay);
        }
        cancel = () => {
          if (finished) {
            return;
          }
          //console.log("OK, I'll stop counting.");
          clearInterval(this.id2);
          this.id2 = null;
          reject();
        };

        if (finished) {
          cancel();
        }
      })
        .then((resolvedValue) => {
          //console.log("Termina");
          this.$emit("input", this.valor);
          finished = true;
          return resolvedValue;
        })
        .catch((err) => {
          //console.log("fracaso");
          finished = true;
          return err;
        });

      var prom = this.promise;

      return { prom, cancel };
    },
  },
  watch: {
    valor() {
      this.refrescarValor().cancel();
      this.refrescarValor();
    },
  },
};
</script>
