<template>
  <div>
    <transition name="sidebar">
      <el-aside
        id="mainSidebar"
        v-if="$store.state.token != null"
        :width="$store.state.sidebar ? '200px' : '65px'"
        ><div id="sidebarCabezera">
          <div id="contenedorImagenCabezera">
            <img
              v-if="$store.state.sidebar == true"
              src="../../../assets/logoestrella.png"
              alt="Logo"
              width="120px"
            />
          </div>
        </div>
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <div style="height: auto">
            <el-menu
              id="sidemenu"
              background-color="#1D282D"
              text-color="#ffffff"
              :collapse-transition="false"
              :collapse="!$store.state.sidebar"
              :default-active="itemMenuActivo"
              :default-openeds="menuItemsAbiertos"
            >
              <div v-for="(item, index) in menuItems" :key="index">
                <el-menu-item
                  v-if="item.children == null && esItemVisible(item)"
                  @click="$router.push(item.path)"
                  :index="item.path"
                >
                  <i :class="item.icon" class="estilobotones"></i>
                  <span slot="title" v-text="item.name"></span>
                </el-menu-item>
                <el-submenu
                  v-if="item.children != null && esMenuVisible(item)"
                  :index="item.path"
                >
                  <template slot="title">
                    <div style="display: flex">
                      <i :class="item.icon" class="estilobotones"></i>
                      <span
                        v-if="$store.state.sidebar == true"
                        slot="title"
                        v-text="item.name"
                      ></span>
                      <i
                        v-if="$store.state.sidebar == true"
                        class="el-icon-arrow-down animacionIcono"
                        style="margin: auto; margin-right: 0px"
                      ></i>
                    </div>
                  </template>
                  <div v-for="(child, j) in item.children" :key="j">
                    <el-menu-item
                      v-if="esItemVisible(child)"
                      @click="$router.push(child.path)"
                      :index="child.path"
                    >
                      <i :class="child.icon" class="estilobotones"></i>
                      <span slot="title" v-text="child.name"></span>
                    </el-menu-item>
                  </div>
                </el-submenu>
              </div>
              <el-menu-item index="logout" @click="logout">
                <i class="el-icon-switch-button estilobotones"></i>
                <span slot="title">Cerrar sesión</span>
              </el-menu-item>
            </el-menu>
          </div>
        </el-scrollbar>
      </el-aside>
    </transition>
  </div>
</template>

<style scoped>
#mainSidebar {
  height: 100vh;
  background: #1d282d;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.459);
}

#sidemenu {
  border-right: 0px;
}

#sidebarCabezera {
  color: #fff;
  background: #1d282d;
}

#contenedorImagenCabezera {
  display: flex;
  height: 70px;
}

#contenedorImagenCabezera > img {
  margin: auto;
  margin-left: 20px;
}
</style>

<style>
.estilobotones {
  margin: auto;
  margin-right: 15px;
  margin-left: 0px;
}
.alineacionicono {
  display: flex;
}
</style>

<style>
@media (min-device-width: 769px) {
  .sidebar-enter-active,
  .sidebar-leave-active {
    transition: all 0.2s ease-in-out;
  }
  .sidebar-enter-active-to {
    transform: translateX(250px);
    margin-left: 0px;
  }
  .sidebar-enter, .sidebar-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(-250px);
    margin-left: -250px;
  }
}
@media only screen and (max-device-width: 768px) {
  .sidebar-enter-active,
  .sidebar-leave-active {
    transition: all 0.2s ease-in-out;
  }
  .sidebar-enter-active-to {
    transform: translateX(250px);
  }
  .sidebar-enter, .sidebar-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(-250px);
  }

  .back-fade-enter-active,
  .back-fade-leave-active {
    transition: opacity 0.1s ease-in-out;
  }
  .back-fade-enter, .back-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>

<script>
import SidebarRoutes from "@/router/sidebarRoutes";

export default {
  name: "sidebar",
  data() {
    return {
      itemMenuActivo: "home",
      menuItems: [],
      menuItemsAbiertos: [],
      empresa: {},
    };
  },
  computed: {},
  created: function () {
    // traer items del menu
    this.menuItems = SidebarRoutes;

    // Corregir item de menu seleccionado al hacer refresh de pagina
    this.resolverItemActivo(this.$router.currentRoute);

    this.$router.afterEach((to) => {
      this.resolverItemActivo(to);
    });
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },

    // Cuando se recarga la pagina, colocar como activo el item correcto de la ruta
    resolverItemActivo(to) {
      this.menuItems.forEach((item) => {
        if (item.children == null) {
          if (to.name == item.name) {
            this.itemMenuActivo = item.path;
          }
        } else {
          item.children.forEach((child) => {
            if (to.name == child.name) {
              this.itemMenuActivo = child.path;
            }
          });
        }
      });
    },

    esItemVisible(item) {
      return true;
      let visible = false;

      // Verificar que el usuario este logueado para procesar los roles
      console.log("permisos", this.$store.state.permisos);
      console.log("item", item);

      if (this.$store.state.permisos == null) {
        return false;
      }

      this.$store.state.permisos.forEach((permisoUsuario) => {
        if (permisoUsuario.code == item.code) {
          visible = true;
        } else if (item.path == "/") {
          //ignorar cuando es pantalla de inicio
          visible = true;
        }
      });

      return visible;
    },

    esMenuVisible(item) {
      return true;
      let visible = false;

      // Verificar que el usuario este logueado para procesar los roles
      if (this.$store.state.permisos == null) {
        return false;
      }

      item.children.forEach((child) => {
        this.$store.state.permisos.forEach((permisoUsuario) => {
          console.log(permisoUsuario);
          if (permisoUsuario.code == child.code) {
            visible = true;
          }
        });
      });

      return visible;
    },
  },
};
</script>
