let routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    noLoginRequerido: true,
  },
];

export default routes;
