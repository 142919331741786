<template>
  <div id="app">
    <el-container>
      <Sidebar id="sidebar"></Sidebar>
      <OcultarSidebar></OcultarSidebar>

      <el-container>
        <maca-titulo-pagina
          :icono="$route.icon"
          :nombrePagina="$route.name"
        ></maca-titulo-pagina>
        <el-main id="main">
          <transition name="fade-quick" mode="out-in">
            <router-view />
          </transition>
        </el-main>
        <el-footer id="footer" height="25px">
          <a href="http://macamedia.com.ar" target="_blank">2020 Macamedia</a>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}

* {
  font-family: "Ubuntu";
}

body {
  background: #e0e6ee;
  margin: 0px;
  overflow: hidden;
}
#sidebar {
  z-index: 2;
}
#main {
  overflow: auto;
  padding-top: 20px;
  height: calc(100vh - 25px); /* Por el footer */
}
#nav a.router-link-exact-active {
  color: #42b983;
}
#footer {
  height: 25px;
  position: fixed;
  bottom: 0px;
  right: 3%;
  background: #8c8c8c4f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#footer > a {
  margin: auto;
  color: white;
  text-decoration: none;
  font-size: 14px;
}
.item-menu {
  text-align: left;
}
</style>

<style scoped>
.fade-quick-enter-active,
.fade-quick-leave-active {
  transition: opacity 0.2s;
}

.fade-quick-enter,
.fade-quick-leave-to {
  opacity: 0;
}
</style>

<script>
import Sidebar from "./components/layout/sidebar";
import OcultarSidebar from "./ocultarSidebar";

export default {
  components: {
    Sidebar,
    OcultarSidebar,
  },
  name: "app",
  data() {
    return {};
  },
  created() {
    if (this.$store.state.usuario != null) {
      this.obtenerPermisos();
    }
  },
  methods: {
    // Comprobar permisos al iniciar
    async obtenerPermisos() {
      let respuestaApi = await this.$maca.api.get(
        "/permiso/obtenerTodosUsuario"
      );

      this.$store.commit("actualizarPermisos", respuestaApi.datos);
    },
  },
};
</script>
