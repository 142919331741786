<template>
  <div>
    <el-header class="tituloPagina">
      <h1 class="tituloPaginaTexto" style="color: #ffffff; padding-top: 2px">
        <i
          :class="icono"
          style="color: #ffffff; margin-right: 6px; padding-left: 4px"
        ></i>
        {{ nombrePagina }}
      </h1>
    </el-header>
    <!-- Actua como margen para el contenido principal de la pag -->
    <div style="height: 50px"></div>
  </div>
</template>


<style>
.tituloPagina {
  display: flex;
  position: fixed;
  height: 50px !important;
  background: #283747;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
.tituloPaginaTexto {
  font-size: 18px;
  margin: auto;
  margin-left: 0px;
}
.tituloPagina .el-row > div i {
  margin-left: 0px;
  margin-right: 10px;
  font-size: 22px;
  display: flex;
}
</style>

<script>
export default {
  props: {
    icono: {
      type: String,
      default: null,
    },
    nombrePagina: {
      type: String,
      default: "Peliculas",
    },
  },
};
</script>

<style></style>
