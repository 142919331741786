let routes = [
  {
    path: "/",
    name: "Inicio",
    icon: "el-icon-house",
    component: () => import("@/views/home"),
  },
  {
    path: "/turnos",
    name: "Turnos",
    icon: "el-icon-date",
    code: "VA_TUR",
    component: () => import("@/views/turnos"),
  },
  {
    path: "/reservas",
    name: "Reservas",
    icon: "el-icon-collection",
    code: "VA_RES",
    component: () => import("@/views/reservas"),
  },
  {
    path: "/turnos-retiro",
    name: "Turnos de retiro",
    icon: "el-icon-date",
    code: "VA_RANR",
    component: () => import("@/views/turnosDeRetiro"),
  },
  {
    path: "/retiros",
    name: "Retiros",
    icon: "el-icon-collection",
    code: "VA_RET",
    component: () => import("@/views/retiros"),
  },
  {
    path: "/oredenTrabajo",
    name: "Órden de Trabajo",
    icon: "el-icon-notebook-1",
    code: "VA_ORDT",
    component: () => import("@/views/ordenTrabajo"),
  },
  {
    path: "/historial",
    name: "Historial",
    icon: "el-icon-time",
    code: "VA_RES",
    component: () => import("@/views/historial"),
  },
  {
    path: "/testimonio",
    name: "Testimonios",
    icon: "el-icon-notebook-1",
    code: "VA_TES",
    component: () => import("@/views/testimonios"),
  },
  {
    path: "/ajustes",
    name: "Ajustes",
    icon: "el-icon-setting",
    children: [
      {
        path: "/areaSubarea",
        name: "Provincia, localidad y barrio",
        icon: "el-icon-office-building",
        code: "VA_ARE",
        component: () => import("@/views/areaSubarea"),
      },
      {
        path: "/tiposBici",
        name: "Tipos de bicis",
        icon: "el-icon-bicycle",
        code: "VA_TBIC",
        component: () => import("@/views/bicis"),
      },
      {
        path: "/productos",
        name: "Productos",
        icon: "el-icon-shopping-bag-2",
        code: "VA_PRO",
        component: () => import("@/views/productos"),
      },
      {
        path: "/tecnicos",
        name: "Técnicos",
        icon: "el-icon-user",
        code: "VA_USE",
        component: () => import("@/views/tecnicos"),
      },
      {
        path: "/clientes",
        name: "Clientes",
        icon: "el-icon-user",
        code: "VA_CLIR",
        component: () => import("@/views/cliente"),
      },
      {
        path: "/usuarios",
        name: "Usuarios",
        icon: "el-icon-user",
        code: "VA_USE",
        component: () => import("@/views/usuarios"),
      },
      {
        path: "/roles",
        name: "Roles",
        icon: "el-icon-suitcase-1",
        code: "VA_ROL",
        component: () => import("@/views/roles"),
      },
      {
        path: "/permisos",
        name: "Permisos",
        icon: "el-icon-unlock",
        code: "A_ROL_PER",
        component: () => import("@/views/permisos"),
      },
    ],
  },
];

export default routes;
