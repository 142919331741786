import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "element-ui/lib/theme-chalk/index.css";
import * as VueGoogleMaps from "vue2-google-maps";
import VCalendar from "v-calendar";
// CSS
require("./assets/app.css");
require("./assets/movil.css");

require("./assets/tema_element.css");
require("./assets/fuentes.css");
require("./macamedia/assets/element_correcciones.css");
require("./assets/fonts/iconos/css/iconos.css");

// Element
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/es";
Vue.use(Element, { locale });

// Componentes y utilidades de macamedia
import "./macamedia";
// Librerias que se importan
import "./sitio/librerias";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCkMhWtsP6qoIVIiymC7RKZHBibxP4PeLc",
    libraries: "places,drawing,visualization",
  },
});

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export { App, router, store };
