var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", width: "100%" } },
    [
      _c(
        "el-select",
        {
          ref: "elSelect",
          attrs: {
            remote: "",
            "reserve-keyword": "",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading || _vm.typeando,
            filterable: _vm.permitirInput,
            placeholder: _vm.placeholder,
            clearable: _vm.clearable,
            size: _vm.size,
            disabled: _vm.disabled,
            multiple: _vm.multiple
          },
          on: {
            change: function($event) {
              return _vm.input($event)
            },
            clear: function($event) {
              return _vm.seleccionarOpcion(null)
            }
          },
          model: {
            value: _vm.seleccionado,
            callback: function($$v) {
              _vm.seleccionado = $$v
            },
            expression: "seleccionado"
          }
        },
        [
          _vm.icon != null
            ? _c("div", { attrs: { slot: "prefix" }, slot: "prefix" }, [
                _c(
                  "div",
                  { staticStyle: { width: "25px", "line-height": "40px" } },
                  [_c("i", { class: _vm.icon })]
                )
              ])
            : _vm._e(),
          _vm.opciones.length > 0
            ? _c(
                "div",
                _vm._l(_vm.opciones, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            : _c("div", [
                _vm.itemLabel == "nombre"
                  ? _c(
                      "div",
                      [
                        _vm.value != null
                          ? _c("el-option", {
                              attrs: {
                                label: _vm.value.nombre,
                                value: _vm.value.id
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("div", [
                      Array.isArray(_vm.itemLabel)
                        ? _c(
                            "div",
                            [
                              _vm.value != null
                                ? _c("el-option", {
                                    attrs: {
                                      label: _vm.obtenerLabel(_vm.value),
                                      value: _vm.value[_vm.itemValue]
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.value != null
                                ? _c("el-option", {
                                    attrs: {
                                      label: _vm.obtenerLabel(_vm.value),
                                      value: _vm.value[_vm.itemValue]
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                    ])
              ])
        ]
      ),
      _c("div", { staticClass: "slot-wrapper" }, [_vm._t("append")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }