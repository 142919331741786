import store from "@/store";
import otherRoutes from "@/router/otherRoutes";
import sidebarRoutes from "@/router/sidebarRoutes";

const CONDITIONS = {
  authenticated: "AUTHENTICATED",
  notAuthenticated: "NOT_AUTHENTICATED",
  permisosValidos: "PERMISOSVALIDOS",
};

function checkAuthenticated() {
  if (store.state.usuario != null) {
    return true;
  }
  return false;
}

function checkNotAuthenticated() {
  if (store.state.usuario == null) {
    return true;
  }
  return false;
}

function checkPermisosValidos(to) {
  let valido = true;

  // Ver pantalla inicio
  if (to.path == "/") {
    return true;
  }

  store.state.permisos.forEach((permisoUsuario) => {
    let rutaSiguiente = null;

    // obtener elemento de sidebarRoutes
    // porque la variable "to" no tiene atributo "code"
    sidebarRoutes.forEach((sidebarRoute) => {
      if (sidebarRoute.name == to.name) {
        rutaSiguiente = sidebarRoute;
      }
      //verificar children
      if (sidebarRoute.children != null) {
        sidebarRoute.children.forEach((child) => {
          if (child.name == to.name) {
            rutaSiguiente = child;
          }
        });
      }
    });

    // obtener elemento de otherRoutes
    // porque la variable "to" no tiene atributo "code"
    otherRoutes.forEach((otherRoute) => {
      if (otherRoute.loginRequerido == false) {
        return;
      }

      if (otherRoute.name == to.name) {
        rutaSiguiente = otherRoute;
      }
      //verificar children
      if (otherRoute.children != null) {
        otherRoute.children.forEach((child) => {
          if (child.name == to.name) {
            rutaSiguiente = child;
          }
        });
      }
    });

    if (permisoUsuario.code == rutaSiguiente.code) {
      valido = true;
    }
  });

  return valido;
}

function validateAccess(condition, params = null) {
  let accessGranted = false;

  switch (condition) {
    case CONDITIONS.authenticated:
      accessGranted = checkAuthenticated();
      break;
    case CONDITIONS.notAuthenticated:
      accessGranted = checkNotAuthenticated();
      break;
    case CONDITIONS.permisosValidos:
      accessGranted = checkPermisosValidos(params);
      break;

    default:
      break;
  }

  return accessGranted;
}

export default { validateAccess, CONDITIONS };
