<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    :width="width"
    :before-close="beforeClose"
  >
    <span class="estiloTituloModal">{{ titulo }}</span>
    <div :key="contentKey" style="margin:30px; padding-top:7px">
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "maca-modal",
  props: {
    titulo: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "900px",
    },
    impedirClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      contentKey: 0,
    };
  },
  methods: {
    abrir() {
      this.contentKey += 1;
      this.visible = true;
    },
    cerrar() {
      this.visible = false;
    },
    beforeClose(done) {
      if (this.impedirClose) {
        return;
      }

      this.$confirm("¿Está seguro de que desea cerrar el formulario?")
        .then((_) => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>
