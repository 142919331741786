import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function obtenerLocalStorage() {
  return localStorage.getItem(process.env.VUE_APP_NAME);
}

let datosLocalStorage = JSON.parse(obtenerLocalStorage());

export default new Vuex.Store({
  state: {
    usuario: datosLocalStorage ? datosLocalStorage.usuario : null,
    token: datosLocalStorage ? datosLocalStorage.token : null,
    roles: datosLocalStorage ? datosLocalStorage.roles : null,
    permisos: datosLocalStorage ? datosLocalStorage.permisos : null,
    sidebar: true,
  },
  mutations: {
    login(state, datosUsuario) {
      state.usuario = datosUsuario.usuario;
      state.token = datosUsuario.token;
      state.roles = datosUsuario.roles;
      state.permisos = datosUsuario.permisos;

      localStorage.setItem(
        process.env.VUE_APP_NAME,
        JSON.stringify({
          usuario: state.usuario,
          token: state.token,
          roles: state.roles,
          permisos: state.permisos,
        })
      );

      state.sidebar = true;
    },
    logout(state) {
      state.usuario = null;
      state.token = null;
      state.roles = null;
      state.permisos = null;

      localStorage.setItem(
        process.env.VUE_APP_NAME,
        JSON.stringify({
          usuario: state.usuario,
          token: state.token,
          roles: state.roles,
          permisos: state.permisos,
        })
      );

      state.sidebar = false;
    },
    actualizarPermisos(state, permisos) {
      state.permisos = permisos;

      localStorage.setItem(
        process.env.VUE_APP_NAME,
        JSON.stringify({
          usuario: state.usuario,
          token: state.token,
          roles: state.roles,
          permisos: state.permisos,
        })
      );
    },
    ocultarSidebar(state) {
      state.sidebar = false;
    },
    mostrarSidebar(state) {
      state.sidebar = true;
    },
  },
  getters: {
    tienePermiso: (state) => (codigo) => {
      let result = false;

      if (state.permisos == null) {
        return false;
      }

      state.permisos.forEach((permiso) => {
        if (permiso.code == codigo) {
          result = true;
        }
      });

      return result;
    },
    tieneRol: (state) => (nombreRol) => {
      let result = false;

      state.usuario.roles.forEach((rol) => {
        if (rol.nombre.toLowerCase() == nombreRol.toLowerCase()) {
          result = true;
        }
      });

      return result;
    },
  },
  actions: {},
  modules: {},
});
